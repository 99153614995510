export enum CRUD_MESSAGES{
SAVE_MSG = "Data Saved Successfully",
EDIT_MSG = "Data Updated Successfully",
DELETE_MSG  = "Data Deleted Successfully!"
}

export enum LOGIN_MESSAGES{
    CREATE_ACC = "Account Create Successfully",
    LOGIN_SUCC = "Login Successfully",
    UNAME_ERROR="Email Must Be Unique",
    LOGIN_ERROR="Incorrect Email Or Password"
}

export enum REPORT_MESSAGES{
    GENERATE_LEDGER="Generated Ledger Report Successfully"
}

export enum VOUCHER_MESSAGE{
    VOUCHER_CREATED="Voucher Created SuccessFully"
}
export enum TAX_TYPE{
    NON_GST="NON GST",
    INCLUSIVE="INCLUSIVE",
    EXCLUSIVE="EXCLUSIVE"
}

export enum PAY_MODE{
    CASH="CASH",
    CREDIT="CREDIT",
    BANK="BANK"
}

export enum TIME_PERIOD{
    CURRENT_MONTH="Current month",
    LAST_MONTH="Last month",
    LAST_THREE_MONTHS="Last 3 months",
    LAST_SIX_MONTHS="Last 6 months",
    CUSTOM="Custom"
}

