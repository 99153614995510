import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { IItem } from "../../../../Common/Interfaces";
import { fetchItemsThunk } from "../../../../Thunk/MasterThunk/ItemThunk";
import { Field, FieldArray, FormikValues, useFormikContext } from "formik";
import { IDeliveryChallanItems } from "../../../../Common/Interfaces/ITransaction";
import { DeliveryChallanItem } from "./DeliveryChallanReturnObjects";

const DeliveryChallanReturnBill = () => {
  const billItemIndex = useRef(-1);
  const dispatch = useAppDispatch();

  const items = useAppSelector<IItem[]>((state) => state.item.allData);
  useEffect(() => {
    dispatch(fetchItemsThunk());
  }, [dispatch]);

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  useEffect(() => {
    if (values && billItemIndex.current !== -1) {
      let TotQty = 0; // Removed colon after TotQty

      values.DeliveryChallanItems?.map(
        (deliveryItem: IDeliveryChallanItems, index: number) => {
          const selectedItem = items?.find(
            (item: IItem) => item.Id === deliveryItem.ItemId
          );

          if (selectedItem) {
            values.DeliveryChallanItems[index].ItemName = selectedItem.ItemName;
            values.DeliveryChallanItems[index].UnitId = selectedItem?.UnitId;
          }
          TotQty += Number(values.DeliveryChallanItems[index].Qty)
            ? Number(values.DeliveryChallanItems[index].Qty)
            : 0;
          values.TotQty = TotQty;
        }
      );
    }
  }, [
    values?.DeliveryChallanItems[billItemIndex?.current]?.ItemId,
    values?.DeliveryChallanItems[billItemIndex?.current]?.Qty,
  ]);

  return (
    <div className="table-responsive SalesFormTableResponsive">
      <table
        className="table SalesFormTable table-responsive-sm"
        style={{ backgroundColor: "white" }}
      >
        <thead style={{ background: "white", position: "sticky", top: "0" }}>
          <tr>
            <th>
              <strong>Sr</strong>{" "}
            </th>

            <th>
              <strong>#</strong>
            </th>
            <th scope="col" className="col-4">
              <strong className="required">Item Name</strong>
            </th>
            <th>
              <strong>Description</strong>
            </th>
            <th>
              <strong className="required">Qty</strong>
            </th>
            <th>
              <strong className="">Unit</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <FieldArray name="DeliveryChallanItems">
            {(fieldArrayProps) => {
              const { remove, form, unshift } = fieldArrayProps;
              const { values } = form;
              const { DeliveryChallanItems } = values;
              return (
                <>
                  {DeliveryChallanItems?.map(
                    (item: IDeliveryChallanItems, index: number) => {
                      return (
                        <tr
                          key={index}
                          className={`${index === 0 ? "FirstItemRow" : null}`}
                        >
                          <td className="px-1 text-center">{index + 1}</td>
                          <td>
                            <div className="text-center">
                              {index == 0 && (
                                <i
                                  className="fa fa-solid fa-plus cursor-pointer"
                                  onClick={() => {
                                    unshift(DeliveryChallanItem);
                                  }}
                                ></i>
                              )}
                              {index > 0 && (
                                <i
                                  className="fa fa-trash cursor-pointer"
                                  onClick={() => remove(index)}
                                ></i>
                              )}
                            </div>
                          </td>
                          <td>
                            <Field
                              as="select"
                              className="SalesFormNewControl w-100 "
                              id="ItemId"
                              name={`DeliveryChallanItems[${index}].ItemId`}
                              onClick={() => (billItemIndex.current = index)}
                              required
                            >
                              <option value="">Choose Item</option>
                              {items?.map((item: IItem, index: number) => (
                                <option key={item.Id} value={item.Id}>
                                  {item.ItemName}{" "}
                                </option>
                              ))}
                            </Field>
                          </td>
                          <td>
                            {" "}
                            <Field
                              type="text"
                              className={`SalesFormNewControl w-100 ${
                                item.ItemId ? "" : "disabled"
                              }`}
                              name={`DeliveryChallanItems[${index}].ItemDescription`}
                              onFocus={() => (billItemIndex.current = index)}
                              disabled={item.ItemId ? false : true}
                            />
                          </td>
                          <td>
                            <Field
                              type="number"
                              min={0}
                              className={`SalesFormNewControl w-100 ${
                                item.ItemId ? "" : "disabled"
                              }`}
                              name={`DeliveryChallanItems[${index}].Qty`}
                              onFocus={() => (billItemIndex.current = index)}
                              disabled={item.ItemId ? false : true}
                            />
                          </td>{" "}
                          <td>
                            <Field
                              type="text"
                              min={0}
                              className={`SalesFormNewControl w-100 ${
                                item.ItemId ? "" : "disabled"
                              }`}
                              name={`DeliveryChallanItems[${index}].UnitId`}
                              onFocus={() => (billItemIndex.current = index)}
                              disabled={item.ItemId ? false : true}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </>
              );
            }}
          </FieldArray>
        </tbody>
      </table>
    </div>
  );
};

export default DeliveryChallanReturnBill;
