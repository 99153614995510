import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: {},
  status: STATUSES.IDLE,
};
const ReportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setLedger(state, action) {
      state.ledgerData = action.payload.data;
    },
    setStock(state, action) {
      state.stockData = action.payload.data;
    },
    setSale(state, action) {
      state.saleData = action.payload.data;
    },
    setOpeningBalance(state, action) {
      state.openingBalance = action.payload.data;
    },

  },
});

export const { setLedger, setStock,setSale } = ReportsSlice.actions;
export default ReportsSlice.reducer;
